import { replacePortfolioPlaceholders } from 'library/utility/content'

/**
 * Filter functions to customize data for portfolios
 */

/**
 * Replace key/value pairs per unique circumstance
 * @param  {obj} orderObject
 * @param  {str} type
 * @return {obj}
 */
export const filterPositionDataDisplay = (key, value, rawData) => {

  if (value && value.link) {
    value.link = filterPathUpdater(value.link)
  }

  if (key === 'IsCanadianPensionPlan') {
    if (value.value === true) {
      value.value = 'Yes'
    }
  }

  if (key === 'IsLong') {
    if (value.value === true) value.value = 'Long'
    if (value.value === false) value.value = 'Short'
  }

  // Deprecated when switching from Tradesmith to Airtables
  // Decimal is a percentage, not true decimal
  // if (key === 'ForwardDividendYield') {
  //   if (value.type === 'percent') value.value = value.value/100
  // }

  if (key === 'TaxStatus') {
    if (value.value === 1) {
      value.value = 'Yes'
    } else {
      value.value = 'No'
    }
  }

  if (key === 'OptionType') {
    switch (value.value) {
      case 1:
        value.value = 'Sell Call'
        break
      case 2:
        value.value = 'Sell Put'
        break
      case 3:
        value.value = 'Buy Call'
        break
      case 4:
        value.value = 'Buy Put'
        break
      default:
        break
    }
  }

  if (key === 'StockType') {
    switch (value.value) {
      case 1:
        value.value = 'Buy Stock'
        break
      case 2:
        value.value = 'Sell Stock'
        break
      default:
        break
    }
  }

  // Currently only used by the api/portfolio/getFormattedByIds endpoint for app
  const textFields = ['Text1', 'Text2', 'Text3', 'Text4', 'Text5']
  if (rawData && textFields.includes(key)) {
    const [string, render] = replacePortfolioPlaceholders(value.value, rawData)
    if (render) value.value = string
  }

  return value
}


/**
 * Modify portfolio data as needed by portfolio
 * @param  {obj} data
 * @param  {str} portfolioId
 * @return {obj}
 */
export const filterPortfolioDataRaw = (data, portfolioId) => {

  // Modify all negative price values to be positive across the board
  for (const position of data) {
    for (const [key, value] of Object.entries(position)) {
      if (key.includes('Price') && typeof value === 'number' && value < 0) position[key] = value * -1
    }
    if (position.Subtrades && position.Subtrades.length > 0) {
      for (const subtrade of position.Subtrades) {
        for (const [k, v] of Object.entries(subtrade)) {
          if (k.includes('Price') && typeof v === 'number' && v < 0) subtrade[k] = v * -1
        }
      }
    }
  }

  // TIA Portfolio
  // Deprecated
  if (portfolioId === '14118') {
    for (const row of data) {

      // Closed only
      if (row.PositionStatus === 'Closed') {
        if (row.Subtrades && row.Subtrades.length > 0) {
          for (const trade of row.Subtrades) {

            // Force negative value on certain subtrades
            if (trade.StockType === 1) {
              trade.CashAllocation = trade.CashAllocation * -1
            }

            if (trade.StockType && trade.OptionType === null) {
              switch (trade.StockType) {
                case 1:
                  trade.OptionType = 'Buy Stock'
                  break
                case 2:
                  trade.OptionType = 'Sell Stock'
                  break
                default:
                  break
              }
            }
          }
        }
      }
    }
  }

  // SEI Portfolio
  // Deprecated
  if (portfolioId === '13168') {

    // Puts Closed
    for (const row of data) {

      // Closed only
      if (row.PositionStatus === 'Closed') {

        // CloseDate replace
        if (!row.CloseDate || row.CloseDate === null) row.CloseDate = row.ExpirationDate ? row.ExpirationDate : ''

        // ClosePrice / CurrentClosePrice replace
        if (!row.ClosePrice || row.ClosePrice === null) row.ClosePrice = '0'
        if (!row.CurrentClosePrice || row.CurrentClosePrice === null) row.CurrentClosePrice = '0'

        if (row.Subtrades && row.Subtrades.length > 0) {
          for (const trade of row.Subtrades) {

            // Force negative value on certain subtrades
            if (trade.OptionType === 4 && trade.OpenPrice > 0) trade.OpenPrice = trade.OpenPrice * -1
            if (trade.OptionType === 4 && trade.CashAllocation > 0) trade.CashAllocation = trade.CashAllocation * -1

            // CloseDate replace
            if (!trade.CloseDate || trade.CloseDate === null) trade.CloseDate = trade.ExpirationDate ? trade.ExpirationDate : ''

            // ClosePrice / CurrentClosePrice replace
            if (!trade.ClosePrice || trade.ClosePrice === null) trade.ClosePrice = '0'
            if (!trade.CurrentClosePrice || trade.CurrentClosePrice === null) trade.CurrentClosePrice = '0'

          }
        }
      }

      // Opened only
      if (row.PositionStatus === 'Open') {

        // Apply to all subtrades
        if (row.Subtrades && row.Subtrades.length > 0) {
          for (const trade of row.Subtrades) {

            // Force negative value on certain subtrades
            if (trade.StockType === 1) {
              trade.CashAllocation = trade.CashAllocation * -1
            }
          }
        }
      }

    }
  }

  return data
  
}

/**
 * For legacy links, replace old urls with new website path
 * @param  {str} path
 * @return {str}
 */
export const filterPathUpdater = path => {

  // Paths
  const tfpPath = 'https://my.threefounderspublishing.com'
  const sprPath = 'https://my.stpaulresearch.com'
  const ppPath = 'https://paradigm.press'
  const newPath = 'https://my.paradigmpressgroup.com'

  // For TFP and SPR
  if (path.includes(tfpPath)) path = path.replace(tfpPath, newPath)
  if (path.includes(sprPath)) path = path.replace(tfpPath, newPath)

  // For PP
  if (path.includes(ppPath)) {
    path = path.replace(ppPath, '')
    path = path.substring(12)
    path = `${newPath}/article/${path}`
  }

  return path
}