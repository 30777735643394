/**
 * Wrapping component to determine if user is authorized to view site
 */

import { useEffect, useContext, useCallback } from 'react'
import { UserContext } from 'library/context/userContext'
import { SiteContext } from 'library/context/siteContext'
import Router, { useRouter } from 'next/router'
import { isEmpty, getCleanPath, getQueryString, getFragmentIdentifier } from 'library/utility/global'

import SiteWrapper from 'components/hoc/site-wrapper'
import Loading from 'components/ui/loading'
import Auth from 'components/user/auth'

const AuthContainer = ({ children }) => {

  // Get user context
  const { userState } = useContext(UserContext)
  const { dataError } = useContext(SiteContext)

  // Get router to determine view classes
  const router = useRouter()

  /**
   * Generate an array of classes that correspond to the current view
   * @param  {obj} router
   * @return {arr}
   */
  const viewClasses = useCallback(router => {
    
    // Set up vars
    let classes = []
    let paths = router.pathname.split('/')
    paths = paths.filter(path => path !== '');

    // Default for home page
    if (paths.length === 0) classes = ['home']

    // Build array based on routing parameters
    for (let i = 0; i < paths.length; i++) {

        // Assign parents
        const prefix = (i !== paths.length - 1 ) ? 'parent-path-' : 'path-'        

        // Define dynamic routes
        if (paths[i].includes('[')) {
          const key = paths[i].replace(/[[\].]/gi, '');
          classes.push(`dynamic-route-${key}`)
          classes.push(prefix + router.query[key])

        // Define default routes
        } else {
          paths[i] = prefix + paths[i]
          classes.push(paths[i])
        }

    }

    return classes
    
  }, [])
  
  // Setup vars for UI display
  let wrapper = <Loading />
  let classes = ['wrapper']

  // Conditional UI display
  switch (true) {
    case userState.isAuthenticated && !isEmpty(userState.accessibleFulfillment):
      wrapper = (
        <SiteWrapper>
          {children}
        </SiteWrapper>
      )
      classes = classes.concat(viewClasses(router)) 
      classes = classes.concat(['logged-in'])
      break
    case dataError:
      wrapper = <Auth />
      classes = classes.concat(['auth-error'])
      break
    case !userState.isAuthenticated && !userState.isLoading:
      wrapper = <Auth />
      classes = classes.concat(['logged-out'])
      break
  }

  return (
    <>
      <div className={classes.join(' ')}>
        {wrapper}
      </div>
    </>
  )
}

export default AuthContainer