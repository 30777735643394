import { useContext } from 'react'
import { UserContext } from 'library/context/userContext'
import { filterDynamicContentEntry, replacePlaceholders, extractLifetimeContentToMap } from 'library/utility/content'

import ReactDOMServer from 'react-dom/server'
import PaygateInline from 'components/article/paygate-inline'

export const useContentFilter = entry => {

  // Get user data and Auth function from user state
  const { userHasAccess, userState } = useContext(UserContext)

  // Create replacement map for placeholders (example: {{firstName}})
  const firstName = userState.account.firstName ? userState.account.firstName : '',
        lastName = userState.account.lastName ? userState.account.lastName : '',
        emailAddress = userState.account.emailAddress ? userState.account.emailAddress : '',
        customerNumber = userState.account.customerNumber ? userState.account.customerNumber : ''

  let defaultFilters = {
    firstName: firstName,
    lastName: lastName,
    fullName: `${firstName} ${lastName}`,
    emailAddress: emailAddress,
    customerNumber: customerNumber
  }

  // Add base64 encoded options for every item
  for (const [key, value] of Object.entries(defaultFilters)) defaultFilters[`${key}Base64`] = btoa(unescape(encodeURIComponent(value)))

  // Look for special lifetime inline-tags
  const [ content, replacementMap ] = extractLifetimeContentToMap(entry.content)
  if (Object.keys(replacementMap).length !== 0) {
    if (entry.cfType === 'article' && !userHasAccess(entry.subscription, ['subscriptionView'], { subType: 'LIFE' })) {
      for (const [key, value] of Object.entries(replacementMap)) {
        replacementMap[key] = ReactDOMServer.renderToStaticMarkup(<PaygateInline article={entry} />)
      }
    }
  }

  // Merge tags with default filters
  defaultFilters = { ...defaultFilters, ...replacementMap }

  // Manually replace preset filters and special tags
  let newEntry = { ...entry }
  newEntry.content = replacePlaceholders(content, defaultFilters)
  return filterDynamicContentEntry(newEntry)

}